<template>
  <div class="overflow-hidden">
    <Content @handleGet="handleGet" />
    <Modal v-if="isOpen" @close="isOpen = false">
      <!-- <div class="">
        <img src="./assets/qrcodeIos.svg" class="w-52 h-52" />

        <p class="text-center text-white">Scan to Download</p>
      </div> -->
      <div className="text-white text-3xl mt-10">
        Coming Soon
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Modal from "./components/Modal.vue";
import Content from "./components/Content.vue";
const isOpen = ref(false);

const handleGet = () => {
  isOpen.value = true;
};
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

.hero {
  .heading {
    color: #292a29;
  }
}

.freedom-section {
  background: #f8f8f8;
  .heading {
    font-family: "Roboto";
    span {
      color: #ea4e4b;
    }
  }
}

.what-we-offer-section {
  .heading {
    font-family: "Roboto";
  }
}
</style>
