<template>
  <div class="overflow-y-auto h-screen" ref="scrollContainer">
    <div ref="navRef" class="w-full">
      <SiteNav @scroll="handleScroll" @handleGet="$emit('handleGet')" />
    </div>

    <section class="">
      <div class="hero bg-white pb-10 md:pb-0 overflow-hidden">
        <div class="w-10/12 mx-auto lg:w-10/12 lg:mx-auto">
          <div
            class="relative lg:py-28 text-center flex flex-col-reverse lg:text-left lg:grid grid-cols-2 lg:gap-8 items-center"
          >
            <div class="">
              <h1 class="font-bold text-primary-deep text-2xl md:text-5xl level-2 mb-6">
                Manage your <br class="hidden md:block" />
                finances
                <br class="block md:hidden" />
                <span class="text-red-200 mx-1 text-secondary-light"> like a pro </span>

                with <br />
                <span class="md:text-secondary"> WorknProsper <br class="hidden md:block" /> </span>
              </h1>
              <p class="text-summary md:w-4/5 mx-auto lg:mx-0 mb-6">
                Work Smarter, Prosper Faster, and Achieve <br />
                Financial Freedom With WorknProsper.   
              </p>
              <div class="hidden md:block">
                <SiteButton color=" bg-secondary text-white" @click="$emit('handleGet')">
                  Get WorknProsper app
                </SiteButton>
              </div>
              <div class="md:hidden">
                <SiteButton color=" bg-secondary text-white" @click="openMobile"> Get WorknProsper app </SiteButton>
              </div>
            </div>
            <div class="max-w-3xl mx-auto lg:absolute -right-24">
              <img src="../assets/img-3.png" class="h-auto lg:h-600" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="flex gap-2 px-6 md:px-32 mb-16">
      <img src="../assets/URE.svg" class="w-12 h-12" />
      <p class="text-sm">
        We are partnered with
        <span class="font-normal md:font-bold mx-1">
          URE Microfinance <br class="block md:hidden" />
          Bank <br class="hidden md:block" />
        </span>
        to ensure the safety of funds
      </p>
    </section>
    <section class="bg-white md:bg-secondary-new py-16">
      <div class="flex flex-col md:grid md:grid-cols-2 items-center">
        <div class="">
          <img src="../assets/tab1Image.png" class="h-64 md:h-453" />
        </div>

        <div class="flex flex-col text-center md:text-left">
          <p class="text-secondary text-sm">ABOUT WORKNPROSPER</p>
          <p class="font-bold text-black text-2xl md:text-3xl mb-4 md:mb-0">
            The perfect financial app for <br />
            employees
          </p>
          <p class="mt-0 md:mt-6 px-4 md:px-0">
            WorknProsper is a comprehensive platform designed to help
            <br class="hidden lg:block" />
            you manage your money, pay bills, budget, save, deal, get
            <br class="hidden lg:block" />
            amazing low-interest loans, and even generate payslips.
          </p>
          <div class="md:hidden">
            <SiteButton color="bg-secondary text-white md:hidden w-8/12 mx-auto mt-6 " @click="openMobile">
              Get WorknProsper app
            </SiteButton>
          </div>

          <div class="mt-6 p-4 bg-white hidden md:flex gap-4 rounded-lg" style="width: fit-content">
            <img src="../assets/barcode.png" class="w-96 md:w-20 h-64 md:h-20" />
            <div class="flex flex-col gap-2">
              <p>Scan to download app. <br />Available on Appstore and Playstore</p>
              <div class="flex gap-4">
                <img src="../assets/apple.png" class="w-6 h-6" />
                <img src="../assets/google-play.png" class="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="my-10 md:my-24 px-4 md:px-0 w-full md:w-10/12 mx-auto">
      <p class="text-center text-black font-bold text-2xl md:text-4xl mb-10">Our Features</p>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 md:w-full mx-auto gap-6 mt-6">
        <DeviceCard
          :IconSrc="flexibleLoans"
          title="Flexible Credit"
          description="Explore our credit options and take advantage of the flexible repayment plans."
        /><DeviceCard
          :IconSrc="investmentOptions"
          title="Explore mouth watering deals "
          description="Unlock exclusive deals and discounts tailored just for you."
        />
        <DeviceCard
          :IconSrc="smartSavings"
          title="Smart savings"
          description="Set personalised savings and watch your savings grow."
        /><DeviceCard
          :IconSrc="generatePayslip"
          title="Generate payslips with ease "
          description="Access and generate your payslips effortlessly. Stay informed about your earnings, deductions, and taxes."
        /><DeviceCard :IconSrc="billPayment" title="Bill Payment " description="Effortlessly pay bills with ease." />
      </div>
    </section>
    <section ref="targetDiv" class="mt-0 md:mt-14 flex justify-center">
      <div
        class="pb-12 md:pb-24 py-20 text-white w-screen"
        :style="{
          backgroundImage: ' url(' + background + ')',
        }"
        style="background-size: cover; background-position: center; background-repeat: no-repeat"
      >
        <p class="font-bold text-4xl text-center">Frequently Asked Questions</p>
        <div class="flex justify-center mt-12">
          <div class="flex flex-wrap justify-center gap-6 rounded-full p-3 mx-auto mb-10">
            <div
              v-for="(value, key) in tabs"
              :key="key"
              :class="{
                ' text-primary-hover bg-white ': activeTab === key,
                ' text-white': activeTab !== key,
              }"
              @click="scrollToLeft(key)"
              class="py-2 px-4 cursor-pointer border border-white tab-item rounded-full"
            >
              <span class="w-full text-sm">
                {{ key }}
              </span>
            </div>
          </div>
        </div>
        <div class="w-10/12 mx-auto overflow-x-auto no-scrollBar" ref="scrollContainer1">
          <div class="flex md:grid md:grid-cols-5 w-max gap-6">
            <div
              v-for="(card, cardIndex) in tabs[activeTab]"
              :key="cardIndex"
              style="max-width: 304px"
              class="border rounded-4xl h-auto overflow-y-auto border-lighterGray p-6 flex flex-col gap-4"
            >
              <p class="text-xl font-medium">
                {{ card.question }}
              </p>
              <p class="text-sm font-normal" v-html="card.answer"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      :style="{
        backgroundImage: ' url(' + background2 + ')',
      }"
      style="background-size: cover; background-position: left; background-repeat: no-repeat"
      class="text-white pl-20 mt-20 mb-24 rounded-4xl hidden md:flex py-32 justify-start items-center gap-9 w-10/12 mx-auto"
    >
      <div>
        <img src="../assets/qrcode.svg" class="w-48 h-48" />
      </div>

      <div class="flex flex-col">
        <p class="font-bold text-4xl">
          Scan to <br />
          download app
        </p>
        <p>
          Available on Apple App store <br />
          and Google Play Store
        </p>
        <div class="flex gap-4 mt-2">
          <img src="../assets/whiteAppStore.svg" class="w-8 h-8" />
          <img src="../assets/whiteGooglePlay.svg" class="w-8 h-8" />
        </div>
      </div>
    </section>
    <section
      class="bg-secondary-new my-10 text-center mb-12 bg-opacity-100 rounded-4xl md:hidden flex p-8 flex-col justify-center items-center gap-4 w-11/12 mx-auto"
    >
      <p class="text-black font-bold text-2xl">Ready to manage your finances today?</p>
      <p class="text-wnpGrey text-base font-normal">
        WorknProsper is available on Apple App store and Google Play Store
      </p>
      <div class="flex gap-4 mb-2">
        <img src="../assets/whiteAppStore.svg" class="w-10 h-10 border border-lighterGray rounded-full" />
        <img src="../assets/whiteGooglePlay.svg" class="w-10 h-10 border border-lighterGray rounded-full" />
      </div>
      <SiteButton color="bg-secondary text-white py-8 text-lg" shadow>
        <a href="https://www.worknprosper.com" target="__blank" class="p-2"> Download WorknProsper App </a>
      </SiteButton>
    </section>
    <div ref="footerRef">
      <SiteFooter worknprosper @scroll="handleScroll" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import SiteNav from "../../../components/wnpSiteNav.vue";
import SiteFooter from "../../../components/SiteFooter.vue";

import SiteButton from "../../../components/SiteButton.vue";
import DeviceCard from "../../../components/wnpDeviceCard.vue";
import background from "../assets/tabsBackground.svg";
import background2 from "../assets/barcode.svg";

import flexibleLoans from "../assets/flexibleLoans.svg";
import investmentOptions from "../assets/investmentOptions.svg";
import smartSavings from "../assets/smartSavings.svg";
import generatePayslip from "../assets/generatePayslip.svg";
import billPayment from "../assets/billPayment.svg";
const scrollContainer = ref(null);
const handleScroll = (e) => {
  console.log(e);
  if (e === "footer") {
    if (footerRef.value) {
      footerRef.value.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  } else if (e === "home") {
    if (navRef.value) {
      navRef.value.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  } else {
    if (targetDiv.value) {
      targetDiv.value.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
};
const openMobile = () => {
  // window.open(
  //   "https://apps.apple.com/ng/app/eazipay-business/id6446281426",
  //   "_blank"
  // );
};
const scrollContainer1 = ref(null);
const scrollToLeft = (key) => {
  activeTab.value = key;
  const container1 = scrollContainer1.value;
  container1.scrollLeft = 0; // Adjust the value as per your requirement
};
const tabs = ref({
  GENERAL: [
    {
      question: "Why choose WorknProsper?",
      answer:
        "WorknProsper is a one-stop-service platform built for all employees,  It offers features and services including payment, savings, credit, and profile management, with a focus on catering to the financial needs and goals of all employees.",
    },
    {
      question: "How can I access the WorknProsper app?",
      answer:
        "You can download the WorknProsper app by visiting your device, Google Play store for Android devices, or App Store for iOS devices. Once there, search for WorknProsper, then download and install it on your device.",
    },
    {
      question: "How do I create a WorknProsper account?",
      answer:
        "Creating an account with WorknProsper has just been made easier. <br> Step 1: Download the WorknProsper app on Google Play or App Store or visit <a href='https://www.WorknProsper.com' target='__blank' >www.WorknProsper.com </a> <br> Step 2: Sign up in a few seconds <br> Step 3:  And you are good to go!",
    },
    {
      question: "What other benefits can I get as a registered user on WorknProsper?",
      answer:
        "WorknProsper can provide a range of benefits to all employees. It includes a seamless and beautiful payment experience, quick and easy credit, savings, and deals. With a focus on catering to the financial needs and goals of all employees.",
    },
    {
      question: "How do I contact the team?",
      answer:
        "Get attention, and empathetic help 24/7. We are available to attend to your inquiries 24/7. Feel free to reach us via <a href='mailto:wnp@myeazipay.ng' > email:wnp@myeazipay.ng</a> , WhatsApp & phone: <a href='tel:+2347000332947' >07000332947 (070003EAZIPAY)</a>. Social media and live chat ",
    },
  ],
  PAYMENT: [
    {
      question: "Why can’t I make a payment without my pin?",
      answer:
        "For security and safety reasons, your pin is required for all transfers. Ensure that it’s a unique pin to just you, and don’t share it with anyone.",
    },
    {
      question: "Is it safe to make payments through the mobile app?",
      answer:
        "Yes, we prioritize the security of your payments. Our mobile app uses encryption and industry-standard security protocols to safeguard your financial information and ensure secure transactions.",
    },
    {
      question: "What type of payment can I make on the app?",
      answer:
        "You can make transfers to all available bank accounts in Nigeria. You can also pay bills (internet, airtime, electricity, cable TV).",
    },
    {
      question: "How do I make payments on WorknProsper?",
      answer:
        " The smartest way to pay is with WorknProsper, we made it so easy to pay bills and send money.  Kindly navigate to ‘make transfer’ on the app and voila! Payment successfully processed in seconds.",
    },
  ],
  CREDIT: [
    {
      question: "What credits are available for me on WorknProsper?",
      answer:
        "You can access a variety of credit options: personal loans and Quick wage. However, Quick Wage is only available for employees of companies using our partner app, Eazipay Business.",
    },
    {
      question: "What are the eligibility criteria for obtaining a credit?",
      answer:
        "The eligibility criteria may vary depending on the type of Credit you are applying for and the company's policies. Personal loans depend on factors such as  completing KYC and using WorknProsper for some time, while for Quick Wage it depends on the employer signing up on our partner app, Eazipay.",
    },

    {
      question: "How long does it take to get a credit approved?",
      answer:
        "Once you submit your credit application, our team will process it promptly. The approval time may differ based on the credit type. Rest assured that we strive to provide you with a quick and efficient credit approval process.",
    },

    {
      question: "What are the interest rates and repayment terms for the credit?",
      answer:
        "After payment is complete, you’re now a full property owner. You will receive legal documents such as your purchase receipts, title deed, registered survey plan, and any other relevant ownership transfer documents available.",
    },
    {
      question: "What are the implications if I decide to exit the agreement?",
      answer:
        "Exiting the agreement has implications such as contract termination and compulsory administrative charges.",
    },
  ],
  LOANS: [
    {
      question: "What are the eligibility criteria for obtaining a loan?",
      answer:
        "You can access a variety of credit options: personal loans and Quick advance. However, Quick advance is only available for employees of companies using our partner app, Eazipay Business.",
    },
    {
      question: "What loans are available for me on WorknProsper?",
      answer:
        "The eligibility criteria may vary depending on the type of Credit you are applying for and the company's policies. Personal loans depend on factors such as  completing KYC and using WorknProsper for some time, while for Quick advance it depends on the employer signing up on our partner app, Eazipay.",
    },
    {
      question: "How long does it take to get a loan approved?",
      answer:
        "Once you submit your loan application, our team will process it promptly. The approval time may differ based on the loan type and the volume of applications. Rest assured that we strive to provide you with a quick and efficient loan approval process.",
    },
    {
      question: "What are the interest rates and repayment terms for the loans?",
      answer:
        "Best believe that WorknProsper has put together incredible loan options with unbelievably amazing rates and repayment periods, Interest rates and repayment terms may vary depending on the loan type. You can find detailed information about interest rates, repayment tenures, and other loan terms within the app's loan section.",
    },
  ],
  "SAVE 2 PROSPER": [
    {
      question: "What type of saving features do we offer?",
      answer: "We offer three main types of savings features: EaziLock, Safe Stash, Target Funds",
    },
    {
      question: "How does EaziLock work?",
      answer:
        "You choose an amount to lock away for a set period (e.g., from 30 days to 2 years). During this time, you cannot withdraw the funds during that period of time.",
    },
    {
      question: "How can I use the Safe Stash feature?",
      answer:
        "The Safe Stash feature allows you to easily set aside money for emergencies. You can track your progress towards your goal and make additional contributions at any time.",
    },
    {
      question: "How can I use the Target Funds feature?",
      answer:
        "Set a specific goal amount and deadline with the Target funds feature. Track your progress and celebrate milestones as you get closer to your goal.",
    },
    {
      question: "What happens when I reach my target funds goal?",
      answer: "You can choose to withdraw the money or continue saving towards a new goal — the choice is yours.",
    },
  ],
});
const activeTab = ref("GENERAL");
const targetDiv = ref(null);
const footerRef = ref(null);
const navRef = ref(null);
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Aeonik";
  src: local("Aeonik"), url(/fonts/Aeonik-Regular.otf) format("truetype");
}

.no-scrollBar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.animation-slides {
  animation: slide 20s linear infinite;
}

@keyframes slide {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(calc(-200%));
  }
}
@media (max-width: 768px) {
  @keyframes slide {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(calc(-100%));
    }
  }
}
</style>
